
// @ts-nocheck - copied from previous version
import { defineComponent } from "vue";

export default defineComponent({
  name: "Uninstalled",
  mounted() {
/*     window.onbeforeunload = function (e) {
      return "Molim ispunite upitnik.";
    };
 */
    window.onload = function () {
      let radios = document.getElementsByName("Option: ");
      let message = document.getElementsByClassName("message")[0];
      let submitButton = document.getElementsByClassName("submit")[0];
      let msgNsubmit = document.getElementsByClassName("msgNsubmit")[0];
      let needComment = false;
      let msgNsubmitOpened = false;

      for (let i = 0; i < radios.length; i++) {
        radios[i].addEventListener("click", showCommentAndSubmitButton);
      }

      submitButton.addEventListener("click", handleFormSubmit, false);
      message.addEventListener("input", changeSubmitButton);

      function changeSubmitButton() {
        if (message.value || !needComment) {
          submitButton.classList.add("enabled");
        } else {
          submitButton.classList.remove("enabled");
        }
      }

      function showCommentAndSubmitButton() {
        if (!msgNsubmitOpened) {
          msgNsubmit.style.display = "block";
          setTimeout(() => {
            msgNsubmit.style.height = "250px";
            setTimeout(() => {
              msgNsubmit.style.opacity = 1;
            }, 250);
          }, 10);
          msgNsubmitOpened = true;
        }
        if (this.value == "PERFECT") {
          message.placeholder = "Dodaj komentar...";
          needComment = false;
        } else if (this.value == "NOT GOOD") {
          message.placeholder = "Dodaj prijedlog...";
          needComment = true;
        } else {
          message.placeholder = "Objasni što nije ispravno...";
          needComment = true;
        }
        changeSubmitButton();
      }
    };

    function getFormData(form) {
      var elements = form.elements;
      var honeypot;

      var fields = Object.keys(elements)
        .filter(function (k) {
          if (elements[k].name === "honeypot") {
            honeypot = elements[k].value;
            return false;
          }
          return true;
        })
        .map(function (k) {
          if (elements[k].name !== undefined) {
            return elements[k].name;
          } else if (elements[k].length > 0) {
            return elements[k].item(0).name;
          }
        })
        .filter(function (item, pos, self) {
          return self.indexOf(item) == pos && item;
        });

      var formData = {};
      fields.forEach(function (name) {
        var element = elements[name];
        formData[name] = element.value;
        if (element.length) {
          var data = [];
          for (var i = 0; i < element.length; i++) {
            var item = element.item(i);
            if (item.checked || item.selected) {
              data.push(item.value);
            }
          }
          formData[name] = data.join(", ");
        }
      });

      formData.formDataNameOrder = JSON.stringify(fields);
      formData.formGoogleSheetName = form.dataset.sheet || "responses";
      formData.formGoogleSend = form.dataset.email || "";
      return {
        data: formData,
        honeypot: honeypot,
      };
    }

    function handleFormSubmit(event) {
      let contactForm = document.getElementsByTagName("form")[0];
      let formSubmited = document.getElementsByClassName("messageSent")[0];
      contactForm.style.opacity = contactForm.style.maxHeight = 0;
      setTimeout(() => {
        formSubmited.style.height = formSubmited.style.width = "auto";
        formSubmited.style.padding = "70px 0 70px";
        formSubmited.style.opacity = 1;
        contactForm.remove();
        window.onbeforeunload = function () {
          null;
        };
      }, 500);

      event.preventDefault();
      var formData = getFormData(contactForm);
      var data = formData.data;

      if (formData.honeypot) {
        return false;
      }

      var url = contactForm.action;
      var xhr = new XMLHttpRequest();
      xhr.open("POST", url);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      var encoded = Object.keys(data)
        .map(function (k) {
          return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
        })
        .join("&");
      xhr.send(encoded);
    }

    window.addEventListener(
      "keydown",
      function (e) {
        if (
          e.keyIdentifier == "U+000A" ||
          e.keyIdentifier == "Enter" ||
          e.keyCode == 13
        ) {
          if (e.target.nodeName == "INPUT" && e.target.type == "text") {
            e.preventDefault();
            return false;
          }
        }
      },
      true,
    );
  },
});
